import firebase from 'firebase/app'
import store from '@/store'

export default async function addData(payload) {
  const { collection, data } = payload
  try {
    const uid = store.getters['userBasic/uid']
    const promise = await firebase.firestore().collection(collection).doc()
    data.docID = promise.id
    data.uid = uid
    data.insertTime = new Date()

    await firebase.firestore().collection(collection).doc(promise.id).set(data, { merge: true })

    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
