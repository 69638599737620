<template>
  <b-row class="match-height">
    <b-col lg="6">
      <h2>サンプルメッセージを格納する</h2>
      <b-button
        variant="primary"
        @click="input"
      >
        登録する
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import testdata from '@/components/message/test/testdata.json'
import addData from '@/firestore/data/create'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  methods: {
    input() {
      /* eslint-disable */
      for (let i in testdata) {
        let item = testdata[i]
        let data = {
          collection: 'messagetest',
          data: item,
        }
        window.console.log('data', data)
        addData(data)
      }
      /* eslint-enable */
      // window.console.log('memo....', testdata)
    },
  },
}
</script>
